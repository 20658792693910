import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { H4, P } from '@components/Parts';
import { useSearchParams } from 'react-router-dom';
import { useApp } from '@contexts/app';
import { useProduct } from '@contexts/product';
import { useBranding } from '@contexts/branding';
import HuddleImage from '@components/HuddleImage';
import HeroImage from '@components/HeroImage';
import CtaButton from '@components/CtaButton';
import MerchantInfo from '@components/MerchantInfo';
import useRedirect from '@hooks/useRedirect';
import { FLOWS } from '../../../../../types/types.d';
import LoadingCard from '@components/LoadingCard';

const {
	REACT_APP_MY_PAGE_URL,
	REACT_APP_FOTBOLL_PLAY_RIGHT_OF_WITHDRAWAL_URL,
	REACT_APP_FOTBOLL_PLAY_URL,
} = process.env;

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
`;

const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-top: 30px;
	padding-right: 20px;
	padding-left: 20px;
	max-width: 630px;
`;

const Header = styled(H4)`
	text-align: center;
	color: ${(props) => props.theme.textColor};
	margin-bottom: 30px;
`;

const Text = styled(P)`
	text-align: center;
	font-size: 14px;
	color: ${(props) => props.theme.textColor};
	& > a {
		color: ${(props) => props.theme.textColor};
		font-family: Gilroy-Regular;
	}
	& > b {
		font-family: Gilroy-Bold;
	}
	@media (max-width: 640px) {
		text-align: left;
	}
`;

const TextInfo = styled(Text)`
	font-size: 13px;
`;

const ProductContainer = styled.div`
	margin-bottom: 30px;
	text-align: left;
`;

const FotbollPlayButton = styled(CtaButton)`
	margin-bottom: 30px;
`;

const MyPageButton = styled(CtaButton)`
	background-color: transparent;
	border: 2px solid #eaeaea;
	color: #101010;
	margin-bottom: 30px;
`;

const Button = styled(CtaButton)`
	margin-bottom: 30px;
`;

function ThankYouPage(): JSX.Element {
	const [searchParams] = useSearchParams();
	const { redirectIfMissingProductId } = useRedirect();
	const { errorMessageConfig, ceeOrder, signOut } = useApp();
	const { teamAbbrv, strings, heroImage } = useBranding();
	const { product, productId, redirectUrl } = useProduct();
	const [isSigningOut, setIsSigningOut] = useState(false);

	const alreadyAccess = searchParams.get('alreadyAccess') === '1';
	const orderId = ceeOrder?.OrderNumber || '';
	const orderDate = moment(ceeOrder?.CreatedAt).format('YYYY-MM-DD [kl] HH:mm') || '';
	const price = ceeOrder?.TotalAmount;
	const vatAmount = ceeOrder?.TotalVatAmount;
	const productName = product?.Name;
	const currency = product?.PricePlans[0]?.PriceCurrency;

	useEffect(() => {
		if (!productId) {
			redirectIfMissingProductId(FLOWS.CLUB_PPV, productId);
		}
	}, [productId, redirectIfMissingProductId]);

	function handleFotbollPlayClick() {
		window.location.href = redirectUrl || (REACT_APP_FOTBOLL_PLAY_URL as string);
	}

	function handleMyPageClick() {
		window.location.href = REACT_APP_MY_PAGE_URL as string;
	}

	async function handleChangeAccountClick() {
		if (!productId) {
			return;
		}
		setIsSigningOut(true);
		const signOutRedirectUrl = new URL(`${teamAbbrv}/ppv`, window.location.origin);
		signOutRedirectUrl.searchParams.set('productid', productId);
		await signOut(signOutRedirectUrl.toString());
	}

	return (
		<Container role="document">
			{heroImage?.url ? (
				<HeroImage url={heroImage?.url || ''} position={heroImage?.position} />
			) : (
				<HuddleImage abbrv={teamAbbrv || ''} />
			)}
			<Content>
				{isSigningOut ? (
					<LoadingCard text="Du loggas ut" />
				) : (
					<>
						{alreadyAccess ? (
							<Header>Du har redan tillgång till {productName}</Header>
						) : (
							<>
								<Header>Tack för ditt köp hos {ceeOrder?.Merchant.Name}</Header>
								<ProductContainer>
									<Text>
										Produktnamn: <b>{productName}</b>
									</Text>
									<Text>
										Pris:
										<b>{` ${price} ${currency} varav moms ${vatAmount} ${currency}`}</b>
									</Text>
								</ProductContainer>
								{strings?.ppv?.orderProductInformation && (
									<ProductContainer>
										<TextInfo
											dangerouslySetInnerHTML={{
												__html:
													strings.ppv.orderProductInformation
														.replace('$orderId', orderId)
														.replace('$orderDate', orderDate)
														.replace(
															'$agreementLink',
															REACT_APP_FOTBOLL_PLAY_RIGHT_OF_WITHDRAWAL_URL ||
																''
														) || '',
											}}
										></TextInfo>
									</ProductContainer>
								)}
							</>
						)}
						{alreadyAccess && (
							<Button
								disabled={errorMessageConfig?.blocking}
								onClick={handleChangeAccountClick}
							>
								Köp {productName} med annat konto
							</Button>
						)}
						<FotbollPlayButton name="fotbollplay" onClick={handleFotbollPlayClick}>
							Tillbaka till FotbollPlay.se
						</FotbollPlayButton>
						<MyPageButton name="my-page" onClick={handleMyPageClick}>
							Fortsätt till Mina Sidor
						</MyPageButton>
						<MerchantInfo />
					</>
				)}
			</Content>
		</Container>
	);
}

export default ThankYouPage;
