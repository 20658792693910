import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useProduct } from '@contexts/product';
import { useBranding } from '@contexts/branding';
import { useApp } from '@contexts/app';
import CtaButton from '@components/CtaButton';
import useRedirect from '@hooks/useRedirect';
import MerchantInfo from '@components/MerchantInfo';
import ErrorMessage from '@components/ErrorMessage';
import { H4, H1, P } from '@components/Parts';
import HuddleImage from '@components/HuddleImage';
import HeroImage from '@components/HeroImage';
import { FLOWS } from '../../../../../types/types.d';
import { Logger } from '@utils/logger';

const logger = new Logger('StartPage - Club Subscription');

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-right: 20px;
	padding-left: 20px;
`;

const Header = styled(H1)`
	color: ${(props) => props.theme.textColor};
`;

const Price = styled(H4)`
	color: ${(props) => props.theme.textColor};
`;

const Text = styled(P)`
	text-align: center;
	font-size: 14px;
	color: ${(props) => props.theme.textColor};
	& > a {
		color: ${(props) => props.theme.textColor};
		font-family: Gilroy-Regular;
	}
	& > b {
		font-family: Gilroy-Bold;
	}
	@media (max-width: 640px) {
		text-align: left;
	}
`;

const TextInfo = styled(Text)`
	margin-top: 20px;
	font-size: 14px;
	max-width: 500px;
`;

const TextContainer = styled.div`
	margin-bottom: 30px;
`;

const Button = styled(CtaButton)`
	margin-bottom: 10px;
`;

function StartPage() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { redirectIfMissingProductId, redirectOnError, redirectIfAlreadyAccess } = useRedirect();
	const { errorMessageConfig, person } = useApp();
	const { productId, product, loading: loadingProduct, hasAccessToProduct } = useProduct();
	const {
		teamAbbrv,
		team,
		merchant,
		strings,
		loading: loadingBranding,
		heroImage,
	} = useBranding();
	const loading = loadingProduct || loadingBranding;

	useEffect(() => {
		if (!team && !loading) {
			redirectOnError('StartPage Club Subscription - Team is missing!', {
				subtitle: 'Klubben kunde ej hittas ',
			});
		}
		if (!person && !loading) {
			redirectOnError('StartPage Club Subscription - Person is missing!');
		}
	}, [loading, person, redirectOnError, team, teamAbbrv]);

	useEffect(() => {
		redirectIfMissingProductId(FLOWS.CLUB_SUBSCRIPTION, productId);
		redirectIfAlreadyAccess(FLOWS.CLUB_SUBSCRIPTION, hasAccessToProduct);
	}, [hasAccessToProduct, productId, redirectIfAlreadyAccess, redirectIfMissingProductId]);

	if (loading) {
		return <></>;
	}

	const title = product?.DisplayName;
	const price = product?.PricePlans[0]?.PriceAmount;
	const link = product?.Custom1 || '';
	const currency = product?.PricePlans[0]?.PriceCurrency;
	const nameOrAbbrv = merchant?.name || teamAbbrv || '';
	const subscriptionInfoUrl = new URL('abonnemang', link);
	// TEMP Disabled for now
	const includesHighlightsPlus = false; //product?.Custom2 === 'HighlightsPlusIncluded';

	return (
		<Container role="document">
			<ErrorMessage />
			{heroImage?.url ? (
				<HeroImage url={heroImage?.url || ''} position={heroImage?.position} />
			) : (
				<HuddleImage abbrv={teamAbbrv || ''} />
			)}
			<Content>
				<TextContainer>
					{title && <Header>{title}</Header>}
					{price && <Price>{`Pris: ${price} ${currency}`}</Price>}
					{strings?.subscriptionPremium?.landingProductInformation && (
						<TextInfo
							dangerouslySetInnerHTML={{
								__html: strings.subscriptionPremium.landingProductInformation
									.replace('$clubName', nameOrAbbrv)
									.replace('$price', price)
									.replace(
										'$subscriptionInfo',
										includesHighlightsPlus
											? strings.subscriptionPremium
													.highlightsPlusInformationFirst
											: ''
									)
									.replace(
										'$subscriptionInfoLink',
										subscriptionInfoUrl.toString()
									),
							}}
						></TextInfo>
					)}
				</TextContainer>
				<Button
					disabled={errorMessageConfig?.blocking}
					onClick={() =>
						navigate({ pathname: 'payment', search: searchParams.toString() })
					}
				>
					{hasAccessToProduct ? 'Ändra betalsätt' : 'Gå till betalning'}
				</Button>
				<TextContainer>
					{includesHighlightsPlus &&
						strings?.subscriptionPremium?.highlightsPlusInformationSecond && (
							<TextInfo
								dangerouslySetInnerHTML={{
									__html: strings.subscriptionPremium
										.highlightsPlusInformationSecond,
								}}
							></TextInfo>
						)}
				</TextContainer>
				{merchant && <MerchantInfo title="Denna produkt köper du av" />}
			</Content>
		</Container>
	);
}

export default StartPage;
