import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useDocumentDataOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { useApp } from '@contexts/app';
import { ClubPalette } from '../style';
import { POPATypes } from '@api/popa/types';
import { fetchMerchant } from '@api/cee/ceeV3';
import { Logger } from '@utils/logger';

const logger = new Logger('MerchantContext');

interface BrandingContextProps {
	teamAbbrv: string | null;
	teamName: string | null;
	setTeamName: (name: string) => void;
	strings: Record<string, any> | null;
	team: any | null;
	palette: ClubPalette | null;
	logo: string | null;
	heroImage: {
		position: string;
		url: string;
	} | null;
	merchant: POPATypes.Merchant | null;
	loading: boolean;
}

export const BrandingContext = React.createContext<BrandingContextProps>({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setTeamName: (name) => {},
	teamName: null,
	teamAbbrv: null,
	strings: null,
	team: null,
	palette: null,
	logo: null,
	heroImage: null,
	merchant: null,
	loading: true,
});

const BrandingProvider: React.FC<any> = ({ children }) => {
	const { tokens, firebaseFirestore } = useApp();
	const [teamName, setTeamName] = useState<string | null>(null);
	const [loadingMerchant, setLoadingMerchant] = useState<boolean>(false);
	const [merchant, setMerchant] = useState<POPATypes.Merchant | null>(null);

	const stringDocRef = firebaseFirestore
		? doc(firebaseFirestore, `/checkout/fotbollplay/strings/sv`)
		: null;
	const [stringsData, stringsLoading] = useDocumentData(stringDocRef);

	const teamDocRef =
		firebaseFirestore && teamName
			? doc(firebaseFirestore, `/checkout/fotbollplay/teams/${teamName || 'default'}`)
			: null;
	const [teamData, teamLoading] = useDocumentDataOnce(teamDocRef);

	useEffect(() => {
		const getMerchant = async () => {
			if (!merchant && teamData?.merchantId && tokens?.accessToken) {
				setLoadingMerchant(true);
				try {
					const fetchedMerchant = await fetchMerchant(
						tokens.accessToken,
						teamData.merchantId
					);
					setMerchant({
						address: fetchedMerchant.Address,
						city: fetchedMerchant.City,
						name: fetchedMerchant.Name,
						orgNr: fetchedMerchant.BusinessRegistrationNumber,
						zipCode: fetchedMerchant.ZipCode,
						supportEmail: fetchedMerchant.SupportEmail,
						phoneNumber: fetchedMerchant.SupportPhoneNumber,
					});
					logger.debug('Fetched merchant', fetchedMerchant);
				} catch (error) {
					console.error(error);
				} finally {
					setLoadingMerchant(false);
				}
			}
		};
		getMerchant();
	}, [merchant, teamData, tokens]);

	const strings = useMemo(() => {
		let mergedStrings = {};
		if (stringsData && teamData) {
			mergedStrings = {
				...stringsData,
				// ...teamData.strings?.sv,
			};
		}
		return mergedStrings;
	}, [stringsData, teamData]);

	return (
		<BrandingContext.Provider
			value={{
				teamName,
				setTeamName,
				strings,
				team: teamData,
				teamAbbrv: teamData?.abbrv || teamName, // TODO teamName is temp fallback for old abbrv uls
				palette: teamData?.palette,
				logo: teamData?.logo,
				heroImage: teamData?.heroImage,
				merchant: merchant || teamData?.merchant,
				loading: stringsLoading || teamLoading || loadingMerchant,
			}}
		>
			{children}
		</BrandingContext.Provider>
	);
};

export const useBranding = () => useContext(BrandingContext);

export default BrandingProvider;
