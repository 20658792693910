import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useProduct } from '@contexts/product';
import { useBranding } from '@contexts/branding';
import { useApp } from '@contexts/app';
import CtaButton from '@components/CtaButton';
import useRedirect from '@hooks/useRedirect';
import MerchantInfo from '@components/MerchantInfo';
import ErrorMessage from '@components/ErrorMessage';
import { H4, H2, H1 } from '@components/Parts';
import HuddleImage from '@components/HuddleImage';
import HeroImage from '@components/HeroImage';
import { FLOWS } from '../../../../../types/types.d';

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-right: 20px;
	padding-left: 20px;
`;

const Header = styled(H1)`
	color: ${(props) => props.theme.textColor};
`;

const Category = styled(H2)`
	margin-top: 15px;
	color: ${(props) => props.theme.textColor};
`;

const Price = styled(H4)`
	color: ${(props) => props.theme.textColor};
`;

const Info = styled(H4)`
	margin-top: 20px;
	font-size: 14px;
	font-family: Gilroy-Regular;
	color: ${(props) => props.theme.textColor};
`;

const PriceContainer = styled.div`
	margin-bottom: 10px;
	max-width: 500px;
`;

const Button = styled(CtaButton)`
	margin-bottom: 30px;
`;

const DEBUG = process.env.REACT_APP_SENTRY_ENV !== 'production';

function StartPage() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const {
		redirectIfMissingTeam,
		redirectIfMissingProductId,
		redirectOnError,
		redirectIfAlreadyAccess,
	} = useRedirect();
	const { errorMessageConfig, person } = useApp();
	const { productId, product, loading: loadingProduct, hasAccessToProduct } = useProduct();
	const {
		teamAbbrv,
		team,
		merchant,
		strings,
		loading: loadingBranding,
		heroImage,
	} = useBranding();
	const loading = loadingProduct || loadingBranding;

	if (DEBUG) {
		console.log('StartPage PPV product', product);
		console.log('StartPage PPV merchant', merchant);
	}

	useEffect(() => {
		if (!team && !loading) {
			redirectOnError('StartPage PPV - Team is missing!', {
				subtitle: 'Klubben kunde ej hittas ',
			});
		}
		if (!person && !loading) {
			redirectOnError('StartPage PPV - Person is missing!');
		}
	}, [loading, person, redirectOnError, team, teamAbbrv]);

	useEffect(() => {
		redirectIfMissingProductId(FLOWS.CLUB_PPV, productId);
		redirectIfAlreadyAccess(FLOWS.CLUB_PPV, hasAccessToProduct);
	}, [
		hasAccessToProduct,
		productId,
		redirectIfAlreadyAccess,
		redirectIfMissingProductId,
		redirectIfMissingTeam,
	]);

	if (loading) {
		return <></>;
	}

	const category = product?.Category;
	const title = product?.DisplayName;
	const price = product?.PricePlans[0]?.PriceAmount;
	const currency = product?.PricePlans[0]?.PriceCurrency;
	const nameOrAbbrv = merchant?.name || teamAbbrv || '';

	return (
		<Container role="document">
			<ErrorMessage />
			{heroImage?.url ? (
				<HeroImage url={heroImage?.url || ''} position={heroImage?.position} />
			) : (
				<HuddleImage abbrv={teamAbbrv || ''} />
			)}
			<Content>
				<PriceContainer>
					{category && <Category>{category}</Category>}
					{title && <Header>{title}</Header>}
					{price && <Price>{`Pris: ${price} ${currency}`}</Price>}
					{strings?.ppv?.landingProductInformation && (
						<Info>
							{strings.ppv.landingProductInformation.replace(
								'$clubName',
								nameOrAbbrv
							)}
						</Info>
					)}
				</PriceContainer>
				<Button
					disabled={errorMessageConfig?.blocking}
					onClick={() =>
						navigate({ pathname: 'payment', search: searchParams.toString() })
					}
				>
					Gå till betalning
				</Button>
				{merchant && <MerchantInfo title="Denna produkt köper du av" />}
			</Content>
		</Container>
	);
}

export default StartPage;
