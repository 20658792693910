import { makeRequest } from '@utils/http';
import { CEETypes } from './types.d';
import { CeeNewKlarnaIntegrationError } from './error';

function createRequestHeaders(userToken: string): Record<string, string> {
	return {
		Authorization: `Bearer ${userToken}`,
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};
}

const { REACT_APP_CEE_BASE_URL } = process.env;

/**
 * Creates a new order and authenticated session.
 * @throws {CeeNewKlarnaIntegrationError}
 */
export async function createOrderRequest(
	userId: string,
	userToken: string,
	productId: string,
	pricePlanId: string,
	idempotencyKey: string,
	acceptMarketing: boolean,
	favoriteTeamId?: string
): Promise<CEETypes.CEEOrderSession> {
	const response = await makeRequest(
		`${REACT_APP_CEE_BASE_URL}/api/billing/v3/orders/${userId}`,
		{
			method: 'POST',
			headers: createRequestHeaders(userToken),
			body: JSON.stringify({
				IdempotencyKey: idempotencyKey,
				SourceClient: 'MyPage',
				Consents: {
					TermsAndConditions: true,
					Marketing: acceptMarketing,
				},
				Products: [
					{
						ProductId: productId,
						PricePlanId: pricePlanId,
					},
				],
				...(favoriteTeamId && { Payee: String(favoriteTeamId) }),
			} satisfies CEETypes.CEECreateOrderBody),
		}
	);

	if (!response.ok) {
		const errorMessage = 'Unable to create order';
		try {
			if (response.headers.get('Content-Type')?.startsWith('application/json')) {
				throw new CeeNewKlarnaIntegrationError(errorMessage, {
					ceeError: await response.json(),
				});
			}
			throw new CeeNewKlarnaIntegrationError(errorMessage, {
				message: await response.text(),
			});
		} catch (e) {
			if (e instanceof CeeNewKlarnaIntegrationError) {
				throw e;
			}
			throw new CeeNewKlarnaIntegrationError(errorMessage);
		}
	}

	return response.json();
}

/**
 * Completes an order
 * @throws {CeeNewKlarnaIntegrationError}
 */
export async function completeOrder(
	personId: string,
	userToken: string,
	klarnaToken: string,
	orderId: string,
	paymentInfo: CEETypes.CEEOrderPaymentInfo
): Promise<void> {
	const response = await makeRequest(
		`${REACT_APP_CEE_BASE_URL}/api/klarna-payment/v3/persons/${personId}/sessions`,
		{
			method: 'POST',
			headers: createRequestHeaders(userToken),
			body: JSON.stringify({
				AuthorizationToken: klarnaToken,
				OrderId: orderId,
				SessionId: paymentInfo.Data.SessionId,
			} satisfies CEETypes.CEECompleteOrderBody),
		}
	);

	if (!response.ok) {
		const errorMessage = 'Unable to complete order';
		try {
			if (response.headers.get('Content-Type')?.startsWith('application/json')) {
				throw new CeeNewKlarnaIntegrationError(errorMessage, {
					ceeError: await response.json(),
				});
			}
			throw new CeeNewKlarnaIntegrationError(errorMessage, {
				message: await response.text(),
			});
		} catch (e) {
			if (e instanceof CeeNewKlarnaIntegrationError) {
				throw e;
			}
			throw new CeeNewKlarnaIntegrationError(errorMessage);
		}
	}
}

/**
 * Fetch a new payment info
 * @throws {CeeNewKlarnaIntegrationError}
 */
export async function fetchNewPaymentInfo(
	userToken: string,
	personId: string,
	orderId: string
): Promise<CEETypes.CEEOrderPaymentInfo> {
	const response = await makeRequest(
		`${REACT_APP_CEE_BASE_URL}/api/klarna-payment/v3/persons/${personId}/orders/${orderId}/newpaymentmethod/paymentinfo`,
		{
			method: 'GET',
			headers: createRequestHeaders(userToken),
		}
	);

	if (!response.ok) {
		throw new Error(`Could not get payment info ${response.status} - ${response.statusText}`);
	}

	const paymentInfo = await response.json();
	return paymentInfo;
}

/**
 * Completes a changed payment
 * @throws {CeeNewKlarnaIntegrationError}
 */
export async function completeChangePayment(
	personId: string,
	userToken: string,
	klarnaToken: string,
	orderId: string,
	paymentInfo: CEETypes.CEEOrderPaymentInfo
): Promise<void> {
	const response = await makeRequest(
		`${REACT_APP_CEE_BASE_URL}/api/klarna-payment/v3/persons/${personId}/sessions/newpaymentmethod`,
		{
			method: 'POST',
			headers: createRequestHeaders(userToken),
			body: JSON.stringify({
				AuthorizationToken: klarnaToken,
				OrderId: orderId,
				SessionId: paymentInfo.Data.SessionId,
			} satisfies CEETypes.CEECompleteChangePaymentBody),
		}
	);

	if (!response.ok) {
		const errorMessage = 'Unable to complete change payment';
		try {
			if (response.headers.get('Content-Type')?.startsWith('application/json')) {
				throw new CeeNewKlarnaIntegrationError(errorMessage, {
					ceeError: await response.json(),
				});
			}
			throw new CeeNewKlarnaIntegrationError(errorMessage, {
				message: await response.text(),
			});
		} catch (e) {
			if (e instanceof CeeNewKlarnaIntegrationError) {
				throw e;
			}
			throw new CeeNewKlarnaIntegrationError(errorMessage);
		}
	}
}

/**
 * Fetches a product on person
 * @throws {Error}
 */
export async function fetchPersonProduct(
	userToken: string,
	personId: string,
	productId: string
): Promise<CEETypes.CEEPersonProduct> {
	const response = await fetch(
		`${REACT_APP_CEE_BASE_URL}/api/billing/v3/persons/${personId}/products/${productId}`,
		{
			method: 'GET',
			headers: createRequestHeaders(userToken),
		}
	);

	if (!response.ok) {
		throw new Error(`Could not get person product ${response.status} - ${response.statusText}`);
	}

	const product = await response.json();
	return product;
}

/**
 * Fetches a product
 * @throws {Error}
 */
export async function fetchProduct(
	userToken: string,
	productId: string
): Promise<CEETypes.CEEProduct> {
	const response = await fetch(`${REACT_APP_CEE_BASE_URL}/api/billing/v3/products/${productId}`, {
		method: 'GET',
		headers: createRequestHeaders(userToken),
	});

	if (!response.ok) {
		throw new Error(`Could not get product ${response.status} - ${response.statusText}`);
	}

	const product = await response.json();
	return product;
}

/**
 * Fetches an order
 * @throws {Error}
 */
export async function fetchOrder(
	userToken: string,
	personId: string,
	orderId: string
): Promise<CEETypes.CEEOrder> {
	const response = await fetch(
		`${REACT_APP_CEE_BASE_URL}/api/billing/v3/orders/${personId}/orders/${orderId}`,
		{
			method: 'GET',
			headers: createRequestHeaders(userToken),
		}
	);

	if (!response.ok) {
		throw new Error(`Could not get order ${response.status} - ${response.statusText}`);
	}

	const product = await response.json();
	return product;
}

/**
 * Fetches a merchant
 * @throws {Error}
 */
export async function fetchMerchant(
	userToken: string,
	merchantId: string
): Promise<CEETypes.CEEMerchant> {
	const response = await fetch(
		`${REACT_APP_CEE_BASE_URL}/api/billing/v3/merchants/${merchantId}`,
		{
			method: 'GET',
			headers: createRequestHeaders(userToken),
		}
	);

	if (!response.ok) {
		throw new Error(`Could not get merchant ${response.status} - ${response.statusText}`);
	}

	const merchant = await response.json();
	return merchant;
}

export default {
	createOrderRequest,
	completeOrder,
	fetchProduct,
	fetchNewPaymentInfo,
	completeChangePayment,
	fetchPersonProduct,
	fetchOrder,
};
