import styled from 'styled-components';

export const HeroImage = styled.div<{ url: string; position?: string }>`
	flex-shrink: 0;
	background-repeat: no-repeat;
	background-size: cover;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: 300px;
	background-position: ${(props) => props.position};
	background-image: ${(props) => `url(${props.url})`};
`;

export default HeroImage;
